import React from 'react';

// should be backissue singular

function BackIssues() {
  return (
    <div>
    BackIssues
    </div>
  );
}

export default BackIssues;
